var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "Card",
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _c("div", { staticClass: "edit-head" }, [
              _c(
                "a",
                { staticClass: "back-title", on: { click: _vm.close } },
                [
                  _c("Icon", { attrs: { type: "ios-arrow-back" } }),
                  _vm._v("返回 "),
                ],
                1
              ),
              _c("div", { staticClass: "head-name" }, [_vm._v("添加")]),
              _c("span"),
              _c(
                "a",
                { staticClass: "window-close", on: { click: _vm.close } },
                [
                  _c("Icon", {
                    staticClass: "ivu-icon-ios-close",
                    attrs: { type: "ios-close", size: "31" },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "Form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-width": 90,
                rules: _vm.formValidate,
              },
            },
            [
              _c(
                "FormItem",
                { attrs: { label: "名称", prop: "name" } },
                [
                  _c("Input", {
                    staticStyle: { width: "400px" },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "FormItem",
                { staticClass: "br" },
                [
                  _c(
                    "Button",
                    {
                      attrs: { loading: _vm.submitLoading, type: "primary" },
                      on: { click: _vm.handleSubmit },
                    },
                    [_vm._v("提交并保存")]
                  ),
                  _c("Button", { on: { click: _vm.handleReset } }, [
                    _vm._v("重置"),
                  ]),
                  _c(
                    "Button",
                    { attrs: { type: "dashed" }, on: { click: _vm.close } },
                    [_vm._v("关闭")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }